<template>
    <div id="detail">
        <datav :id="id"/>
    </div>
</template>

<script>
    import datav from '../components3/index.vue'

    export default {
        name: 'app',
        components: {
            datav
        },
        data() {
            return {
                id: null
            }
        },
        created() {
            this.id = this.$route.params.id;
            console.log(this.id)
        },
    }
</script>

<style lang="less">

</style>
