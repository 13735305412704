<template>
    <div id="data-view">

        <div class="head">
            <div style="display:inline;"><h1 style="background-image:-webkit-linear-gradient(bottom,#0099ff,#bde4ff,#262668);-webkit-background-clip:text;-webkit-text-fill-color:transparent;">{{detail.title}}</h1></div>
        </div>

        <div class="mainbox" style="display:flex;flex-direction:row;flex-wrap:nowrap;justify-content:center;">
            <div style="width:25%;padding: 0 0.13rem;">
                <div class="boxall" style="height:calc(33.3333% - .25rem)">
                    <div class="tit01">一周不良品统计</div>
                    <div class="nav01" id="weekBadData" style="width: 100%; margin:0;">

                    </div>
                    <div class="boxfoot"></div>
                </div>

                <div class="boxall" style="height:calc(66.6666% - .25rem); margin-bottom:.25rem;">
                    <div class="tit01">一周工序报工情况</div>
                    <div class="nav01" id="weekWorkData" style="width:100%;margin:0;"></div>
                    <div class="boxfoot"></div>
                </div>

                <!--                <div class="boxall" style="height:calc(33.3333% - .25rem)">-->
                <!--                    <div class="tit01">一周产量均差</div>-->
                <!--                    <div class="nav01" id="weekProductionChart">-->

                <!--                    </div>-->
                <!--                    <div class="boxfoot"></div>-->
                <!--                </div>-->
            </div>
            <div style="width:50%;padding: 0 0.13rem;">

                <div style="height:calc(33.3333% - .25rem);margin-bottom:.25rem;">
                    <div class="boxall" style="height:100%;width:calc(50% - .08rem);float:left;">
                        <div style="height:100%;width:calc(50% - .08rem);float:left;">
                            <div id="produceRateData" style="height:100%;width:100%;margin:0;"></div>
                        </div>
                        <div style="height:100%;width:calc(50% - .08rem);float:right;">
                            <div class="tit01">总体生产情况</div>
                            <div>
                                <div class="p4"><span>计划排产</span> <span>{{detail.produceProcessData.num1}}</span></div>
                                <div class="p4"><span>已经生产</span> <span>{{detail.produceProcessData.num2}}</span></div>
                                <div class="p4"><span>不良品</span> <span>{{detail.produceProcessData.num3}}</span></div>
                                <div class="p4"><span>良品率</span> <span>{{detail.produceProcessData.num4}}</span></div>
                            </div>
                        </div>
                        <div class="boxfoot"></div>
                    </div>
                    <div class="boxall" style="height:100%;width:calc(50% - 0.08rem);float:right;">

                        <div class="tit01">一周入库情况</div>
                        <div class="nav01" id="weekFinishData"></div>
                        <div class="boxfoot"></div>
                    </div>
                </div>

                <div class="boxall" style="height:calc(66.666666% - .25rem)">
                    <div class="tit03">近期生产计划列表</div>
                    <div class="boxnav nav03">
                        <div class="listhead listhead1">
                            <span>工单号</span>
                            <span>产品</span>
                            <span>任务数</span>
                            <span>完成数</span>
                            <span>工单状态</span>
                            <span>完成率</span>
                        </div>
                        <div class="listnav listnav1">
                            <ul id="orderPlanList">
                                <vue-seamless-scroll :data="detail.progressData" :class-option="optionHover" style="height:450px;overflow: hidden;">
                                    <li v-for="item in detail.progressData">
                                        <span>{{item.task_work_no}}</span>
                                        <span>{{item.product_name}}</span>
                                        <span>{{item.task_work_num}}</span>
                                        <span>{{item.task_finish_num}}</span>
                                        <span :style="{background: item.state_text_color}">{{item.state_text}}</span>
                                        <span class="text-green">{{item.task_finish_rate}}</span>
                                    </li>
                                </vue-seamless-scroll>
                            </ul>
                        </div>
                    </div>
                    <div class="boxfoot"></div>
                </div>
            </div>
            <div style="width:25%;padding: 0 0.13rem;">
                <div class="boxall" style="height:calc(33.3333% - .25rem); margin-bottom: .25rem;">
                    <div class="tit01">当日设备运行情况</div>
                    <div class="nav01" id="machineRateData" style="width:100%;margin:0;"></div>
                    <div class="boxfoot"></div>
                </div>

                <div class="boxall" style="height:calc(66.666666% - .25rem);">
                    <div class="tit03">当前设备运行状态</div>
                    <div class="boxnav nav03">
                        <div class="listhead listhead1">
                            <span>设备编号</span>
                            <span>状态</span>
                            <span>加工计数</span>
                            <span>加工时间</span>
                            <span>循环时间</span>
                        </div>
                        <div class="listnav listnav1">
                            <ul id="productList">
                                <vue-seamless-scroll :data="detail.machineBoardData" :class-option="optionHover" style="height:450px;overflow: hidden;">
                                    <li v-for="item in detail.machineBoardData">
                                        <span>{{item.machine_no}}</span>
                                        <span :style="{background: item.machine_status_color}">{{item.machine_status}}</span>
                                        <span>{{item.machine_workcounts_total}}件</span>
                                        <span>{{item.machine_cycletime_total}}秒</span>
                                        <span>{{item.machine_cycletime_cur}}秒</span>
                                    </li>
                                </vue-seamless-scroll>
                            </ul>
                        </div>

                    </div>
                    <div class="boxfoot"></div>
                </div>


            </div>
        </div>


    </div>
</template>

<script>
    import _config from "../config";
    import WebsocketHeartbeatJs from 'websocket-heartbeat-js';
    import * as echarts from 'echarts'
    import {fitChartSize} from "../utils/chartSizeUtil";

    export default {
        name: 'DataView',
        props: {
            id: {
                type: String,
                default: null
            },
        },
        data() {
            return {
                detail: {
                    title: '弗勒MES可视化大屏',
                    digitalFlopData: [],
                    progressData: {},
                    machineBoardData: {},
                    machineReportData: {},
                    produceProcessData: {},
                    machineRateData: [],
                }
            }
        },
        computed: {
            optionHover() {
                return {
                    step: 0.5, // 数值越大速度滚动越快
                    limitMoveNum: 2, // 开始无缝滚动的数据量 this.dataList.length
                    hoverStop: true, // 是否开启鼠标悬停stop
                    direction: 1, // 0向下 1向上 2向左 3向右
                    openWatch: true, // 开启数据实时监控刷新dom
                    singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
                    singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
                    waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
                };
            },
        },
        methods: {
            getData() {
                let _this = this;
                this.axios.post("screen.index/detail", {id: _this.id}).then(res => {
                    console.log(res.data);
                    //_this.detail.title = res.data.title;
                    _this.detail.digitalFlopData = res.data.digitalFlopData;
                    _this.detail.progressData = res.data.progressData;
                    _this.detail.machineBoardData = res.data.machineBoardData;
                    _this.detail.machineReportData = res.data.machineReportData;
                    _this.detail.produceProcessData = res.data.produceProcessData;

                    //一周不良品统计
                    var weekBadData = {
                        tooltip: {
                            trigger: 'axis',
                            axisPointer: {            // 坐标轴指示器，坐标轴触发有效
                                type: 'shadow'        // 默认为直线，可选为：'line' | 'shadow'
                            }
                        },
                        textStyle:{
                            fontSize: fitChartSize(12)
                        },
                        grid: {
                            top:'3%',
                            left: 0,
                            right: 0,
                            bottom: 0,
                            containLabel: true
                        },
                        xAxis: [
                            {
                                type: 'category',
                                data: res.data.weekBadData.day,
                                axisTick: {
                                    alignWithLabel: true
                                },
                                axisLabel:{
                                    fontSize:fitChartSize(12)
                                }
                            }
                        ],
                        yAxis: [
                            {
                                type: 'value',
                                axisLabel:{
                                    fontSize:fitChartSize(12)
                                }
                            }
                        ],
                        series: [
                            {
                                name: '不良品',
                                type: 'bar',
                                barWidth: '30%',
                                data: res.data.weekBadData.value,
                                label: {show: true}
                            }
                        ]
                    };
                    weekBadData && echarts.init(document.getElementById('weekBadData')).setOption(weekBadData);

                    //一周工序报工情况
                    var weekWorkData = {
                        grid: {
                            top: 0,
                            bottom: 0,
                            left:0,
                            right:0,
                            containLabel: true
                        },
                        textStyle: {
                            fontSize: fitChartSize(12)
                        },
                        yAxis: {
                            type: 'category',
                            data: res.data.weekWorkData.work_name,
                            axisLabel:{
                                fontSize:fitChartSize(12),
                            }
                        },
                        xAxis: {
                            type: 'value',
                            axisLabel:{
                                fontSize:fitChartSize(12),
                            }
                        },
                        series: [
                            {
                                data: res.data.weekWorkData.total_num,
                                type: 'bar',
                                label: {
                                    show: true,
                                    position: 'inside'
                                },
                            }
                        ]
                    };
                    weekWorkData && echarts.init(document.getElementById('weekWorkData')).setOption(weekWorkData);

                    //生产占比
                    var produceRateData = {
                        textStyle: {
                            fontSize: fitChartSize(12)
                        },
                        series: [
                            {
                                type: 'gauge',
                                startAngle: 90,
                                endAngle: -270,
                                pointer: {
                                    show: false
                                },
                                progress: {
                                    show: true,
                                    overlap: false,
                                    roundCap: true,
                                    clip: false,
                                    itemStyle: {
                                        borderWidth: 0,
                                        borderColor: '#464646'
                                    }
                                },
                                axisLine: {
                                    lineStyle: {
                                        width: fitChartSize(10)
                                    }
                                },
                                splitLine: {
                                    show: false,
                                },
                                axisTick: {
                                    show: false
                                },
                                axisLabel: {
                                    show: false,
                                },
                                data: res.data.produceRateData,
                                title: {
                                    fontSize: 14
                                },
                                detail: {
                                    width: 50,
                                    height: 14,
                                    fontSize: fitChartSize(14),
                                    color: 'inherit',
                                    borderColor: 'inherit',
                                    borderRadius: 20,
                                    borderWidth: 0,
                                    formatter: '{value}%'
                                }
                            }
                        ]
                    };
                    produceRateData && echarts.init(document.getElementById('produceRateData')).setOption(produceRateData);

                    //一周入库情况
                    var weekFinishData = {
                        tooltip: {
                            trigger: 'axis',
                            axisPointer: {            // 坐标轴指示器，坐标轴触发有效
                                type: 'shadow'        // 默认为直线，可选为：'line' | 'shadow'
                            }
                        },
                        textStyle: {
                            fontSize: fitChartSize(12)
                        },
                        grid: {
                            top:'3%',
                            left: 0,
                            right: 0,
                            bottom: 0,
                            containLabel: true
                        },
                        xAxis: [
                            {
                                type: 'category',
                                data: res.data.weekStockData.day,
                                axisTick: {
                                    alignWithLabel: true
                                },
                                axisLabel:{
                                    fontSize:fitChartSize(12)
                                }
                            }
                        ],
                        yAxis: [
                            {
                                type: 'value',
                                axisLabel:{
                                    fontSize:fitChartSize(12)
                                }
                            }
                        ],
                        series: [
                            {
                                name: '入库',
                                type: 'bar',
                                barWidth: '30%',
                                data: res.data.weekStockData.value,
                                label: {show: true}
                            }
                        ]
                    };
                    weekFinishData && echarts.init(document.getElementById('weekFinishData')).setOption(weekFinishData);


                    //每日设备运行情况
                    var machineRateData = {
                        tooltip: {
                            trigger: 'item'
                        },
                        textStyle: {
                            fontSize: fitChartSize(12)
                        },
                        series: [
                            {
                                name: '统计',
                                type: 'pie',
                                radius: '50%',
                                data: res.data.machineRateData,
                            }
                        ]
                    };
                    machineRateData && echarts.init(document.getElementById('machineRateData')).setOption(machineRateData);

                }).catch(err => {
                    console.log('err', err)
                });
            },


        },
        mounted() {
            this.getData();
            let _this = this;
            let websocketHeartbeatJs = new WebsocketHeartbeatJs({
                url: _config.websocketUrl,
                pingTimeout: 15000,
                pongTimeout: 10000,
                reconnectTimeout: 2000,
                pingMsg: "heartbeat"
            });
            websocketHeartbeatJs.onopen = function () {
                console.log('connect success');
                websocketHeartbeatJs.send('hello server');
            }
            websocketHeartbeatJs.onmessage = function (e) {
                console.log(`onmessage: ${e.data}`);
                if (e.data == 'screenUpdate') {
                    _this.getData();
                }
            }
            websocketHeartbeatJs.onreconnect = function () {
                console.log('reconnecting...');
            }
        },
    }
</script>

<style lang="less">
    ol, ul, p, h1, h2, h3, h4, h5, h6 {
        padding: 0;
        margin: 0
    }

    li {
        list-style-type: none;
    }


    .clearfix:after, .clearfix:before {
        display: table;
        content: " "
    }

    .clearfix:after {
        clear: both
    }


    #data-view {
        padding: 0px;
        margin: 0px;
        background: linear-gradient(25deg, #0f2249, #182e5a 20%, #0f2249 40%, #182e5a 60%, #0f2249 80%, #182e5a 100%);
        color: #fff;
        font-size: .1rem;
        height: 100vh;
    }

    .head {
        height: 1.05rem;
        background: url(../assets/head_bg.png) no-repeat center center;
        background-size: 100% 100%;
        position: relative
    }

    .head h1 {
        color: #bde4ff;
        text-align: center;
        font-size: .4rem;
        line-height: 1.05rem;
        letter-spacing: .06rem;
    }

    .head h1 img {
        width: 1.5rem;
        display: inline-block;
        vertical-align: middle;
        margin-right: .2rem
    }


    .mainbox {
        padding: .4rem 0rem 0rem 0rem;
        height: calc(100% - 1.05rem);
    }

    .mainbox > ul {
        margin-left: .1rem;
        margin-right: .1rem;
        height: 100%;
    }

    .mainbox > ul > li {
        float: left;
        padding: 0 .13rem;
        height: 100%;
    }

    .mainbox > ul > li {
        width: 25%
    }

    .mainbox > ul > li:nth-child(2) {
        width: 50%
    }


    .boxall {
        border: 1px solid #3486da;
        background: rgba(0, 70, 190, .1);
        padding: .15rem;
        position: relative;
        margin-bottom: .25rem;
        z-index: 10;
    }

    .boxall:before,
    .boxall:after {
        position: absolute;
        width: .15rem;
        height: .15rem;
        content: "";
        border-top: 3px solid #3486da;
        top: -2px;
    }

    .boxall:before, .boxfoot:before {
        border-left: 3px solid #3486da;
        left: -2px;
    }

    .boxall:after, .boxfoot:after {
        border-right: 3px solid #3486da;
        right: -2px;
    }

    .boxfoot {
        position: absolute;
        bottom: 0;
        width: 100%;
        left: 0;
    }

    .boxfoot:before,
    .boxfoot:after {
        position: absolute;
        width: .15rem;
        height: .15rem;
        content: "";
        border-bottom: 3px solid #3486da;
        bottom: -2px;
    }


    .tit01 {
        background: linear-gradient(to right, rgba(48, 82, 174, 1), rgba(48, 82, 174, 0));
        color: #fff;
        font-size: .22rem;
        padding: .03rem .1rem;
    }

    .tit022 {
        background: #4881d0;
        color: #fff;
        position: absolute;
        font-size: .25rem;
        padding: .02rem 0;
        text-align: center;
        width: fit-content;
        margin-left: -1.2rem;
        margin-top: -.35rem;
        letter-spacing: .05rem;
    }

    .tit02 {
        text-align: center;
        background: #4881d0;
        color: #fff;
        font-size: .25rem;
        line-height: .5rem;
        letter-spacing: .05rem;
        margin-top: -.4rem;
    }

    .tit03 {
        text-align: center;
        color: #fff;
        font-size: .25rem;
        line-height: .5rem;
        letter-spacing: .05rem;
    }

    .tit04 {
        text-align: center;
        background: #0c2854;
        color: #fff;
        font-size: .25rem;
        line-height: .6rem;
        letter-spacing: .05rem;
    }

    .wrap {
        height: 2.54rem;
        overflow: hidden;
    }

    .wrap li {
        line-height: .42rem;
        height: .42rem;
        font-size: .18rem;
        text-indent: .24rem;
        margin-bottom: .1rem;
    }

    .wrap li p {
        border: 1px solid rgba(25, 186, 139, .17);
        color: rgba(255, 255, 255, .6);
    }

    .boxnav {
    }

    .nav01 {
        height: calc(100% - .3rem);
        padding-top: .2rem;
    }

    .nav02 {
        height: calc(100%);
        display: flex;
        align-items: center;
    }

    .nav03 {
        height: calc(100% - .47rem);
        padding-top: .2rem;
    }

    .nav04 {
        height: calc(100% - .7rem);
    }

    .nav02 > div {
        width: 100%;
    }

    .nav02 p {
        text-align: center;
        color: #fff;
    }

    .nav02 .p1 {
        font-size: .3rem;
        letter-spacing: .02rem;
    }

    .p2 {
        font-size: .6rem;
        letter-spacing: .05rem;
        padding: .1rem 0;
        width: 100%;
        text-align: center;
    }

    .nav02 .p3 span {
        text-align: center;
        color: #fff;
        display: inline-block;
        width: 45%;
        font-size: .3rem;
    }

    .p4 {
        font-size: .2rem;
        text-align: left;
        margin-top: .2rem;
    }

    .dot {
        width: .22rem;
        height: .22rem;
        display: inline-block;
        border-radius: 1rem;
        position: relative;
        top: .04rem;
        margin-right: .1rem;
    }

    .dot1 {
        background: #ff0006
    }

    .dot2 {
        background: #00ea37
    }

    .dot4 {
        background: #ebe72a
    }

    .dot3 {
    }

    .text-green {
        color: #00ea37
    }

    .text-red {
        color: #ff0006;
    }

    .text-yellow {
        color: #ebe72a;
    }

    .text-blue {
        color: #425ba8;
    }

    .text-black {
        color: #090909;
    }

    .listhead {
        height: .55rem;
        display: flex;
        justify-content: space-between;
    }

    .listnav {
        height: calc(100% - .55rem);
        overflow: hidden;
    }

    .listnav ul li {
        height: 100%;
        display: flex;
        justify-content: space-between;
    }

    .listhead span, .listnav li span {
        width: 100%;
        align-items: center;
        justify-content: center;
        display: flex;
        height: .55rem;
    }

    .listhead1 span, .listnav1 li span {
        border: 1px solid #3486da;
    }

    .listnav ul li span {
        font-size: .14rem;
    }

    .listnav2 ul li:nth-child(odd) {
        background: #0c2854;
    }

    .listhead1 span {
        background: #0c2854;
        font-size: .2rem;
        color: #3486da;
        font-weight: bold;
    }

    .listhead2 span {
        font-size: .2rem;
        color: #3486da;
        border-bottom: 1px solid #0c2854;
        font-weight: bold;
    }

    .loadBtn {
        width: 1rem;
        font-size: .2rem;
        font-weight: bold;
        background: #3c6a97;
        border-bottom: 1px solid #0c2854;
    }

    .input {
        background: #3c6a97;
        font-size: .2rem;
        font-weight: bold;
        text-align: center;
    }


    /*Plugin CSS*/
    .str_wrap {
        overflow: hidden;
        width: 100%;
        position: relative;
        -moz-user-select: none;
        -khtml-user-select: none;
        user-select: none;
        white-space: nowrap;
    }


    .str_move {
        white-space: nowrap;
        position: absolute;
        top: 0;
        left: 0;
        cursor: move;
    }

    .str_move_clone {
        display: inline-block;
        vertical-align: top;
        position: absolute;
        left: 100%;
        top: 0;
    }

    .str_vertical .str_move_clone {
        left: 0;
        top: 100%;
    }

    .str_down .str_move_clone {
        left: 0;
        bottom: 100%;
    }

    .str_vertical .str_move,
    .str_down .str_move {
        white-space: normal;
        width: 100%;
    }

    .str_static .str_move,
    .no_drag .str_move,
    .noStop .str_move {
        cursor: inherit;
    }

    .str_wrap img {
        max-width: none !important;
    }

    .payment-time {
        padding-right: 10px;
    }
</style>